import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    invoice_statuses: [],
    invoices: [],
    invoice: {},
    company_details: {},
    only_for_stock: null,
    document_warehouse_posneg: null,
    invoiceSignatureImage: null,
  },
  getters: {
    getInvoices(state) { return state.invoices },
    getInvoice(state) { return state.invoice },
    getSignatureImage(state) { return state.invoiceSignatureImage },
    getInvoiceStatuses(state) { return state.invoice_statuses },
    getCompanyDetails(state) { return state.company_details },
    getOnlyForStock(state) { return state.only_for_stock },
    getWarehousePosNeg(state) { return state.document_warehouse_posneg },
  },
  mutations: {
    SET_INVOICES(state, data) { state.invoices = data },
    SET_INVOICE_SIGNATURE_IMAGE(state, data) { state.invoiceSignatureImage = data },
    SET_INVOICE_STATUSES(state, data) { state.invoice_statuses = data },
    SET_INVOICE(state, data) { state.invoice = data },
    SET_COMPANY_DETAILS(state, data) { state.company_details = data },
    SET_INVOICE_ONLY_FOR_STOCK(state, data) { state.only_for_stock = data },
    SET_INVOICE_WAREHOUSE_POSNEG(state, data) { state.document_warehouse_posneg = data },
    SET_INVOICE_SIGNATURES(state, data) {
      state.invoice.signature_vergos = data.signature_vergos
      state.invoice.signature_customer = data.signature_customer
      state.invoice.signature_datetime = data.signature_datetime
    },
  },
  actions: {
    async fetchInvoices({ commit }) {
      const response = await axiosIns.get('/invoices')
      commit('SET_INVOICES', response.data.data.invoices)
      commit('SET_INVOICE_STATUSES', response.data.data.invoice_statuses)
    },
    async fetchInvoiceSignatureImage({ commit }, payload) {
      const response = await axiosIns.get(payload, { responseType: 'arraybuffer' });
      // Convert the ArrayBuffer to Base64
      const base64 = btoa(
          new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
          )
      );
      // Create a data URL
      const imageDataUrl = `data:image/png;base64,${base64}`;
      commit('SET_INVOICE_SIGNATURE_IMAGE', imageDataUrl);
    },
    async setInvoiceSignatureImage({ commit }, payload) {
      commit('SET_INVOICE_SIGNATURE_IMAGE', payload);
    },
    async fetchInvoice({ commit }, payload) {
      const response = await axiosIns.get('/invoices/'+payload)
      commit('SET_INVOICE', response.data.data.invoice)
      commit('SET_COMPANY_DETAILS', response.data.data.company_details)
      commit('SET_INVOICE_ONLY_FOR_STOCK', response.data.data.only_for_stock)
      commit('SET_INVOICE_WAREHOUSE_POSNEG', response.data.data.document_warehouse_posneg)
    },
    async saveSignatures({ commit }, payload) {
      const response = await axiosIns.post('/invoices/save-signatures', payload)
      commit('SET_INVOICE_SIGNATURES', response.data.data.invoice)
      return response.data
    },
    async sendEmailDocument({ commit }, payload) {
      const response = await axiosIns.post('/invoices/send-email-document', payload)
      return response.data
    },
    // async fetchAuctionOffers({ commit }, auctionId) {
    //   if (auctionId && auctionId !== '') {
    //     localStorage.setItem('auctionId', auctionId)
    //   }
    //   const user = JSON.parse(localStorage.getItem('userData'))
    //   const auction = localStorage.getItem('auctionId')
    //   const payload = {
    //     auctionId: auction,
    //     userId: user.id,
    //   }
    //   const response = await axiosIns.post('/auctions-offers', payload)
    //   localStorage.setItem('auction_offers', JSON.stringify(response.data.data))
    //   commit('SET_AUCTION_OFFERS', response.data.data)
    // },
  },
}
