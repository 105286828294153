import Vue from 'vue'

// axios
import axios from 'axios'

const token = localStorage.getItem('accessToken')
const axiosIns = axios.create({
  // baseURL: 'https://vergosapi.test/api/',
  baseURL: 'https://api.vergosauctions.com/api/',
  headers: { Authorization: `bearer ${token}` },
})

Vue.prototype.$http = axiosIns

export default axiosIns
